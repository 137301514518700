import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import AboutUs from "./routes/AboutUs";
import Cleaning from "./routes/Cleaning";
import Clients from "./routes/Clients";
import Contact from "./routes/Contact";
import Apply from "./routes/Apply";
import Painting from "./routes/Painting";
import Home from "./routes/Home";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./routes/NotFound";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="clients" element={<Clients />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="apply" element={<Apply />} />
          <Route path="cleaning" element={<Cleaning />} />
          <Route path="painting" element={<Painting />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
