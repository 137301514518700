import {
  Container,
  Card,
  Typography,
  Avatar,
  useTheme,
  Box,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactIcon = (props: { icon: string }) => {
  switch (props.icon) {
    case "phone":
      return <PhoneIcon fontSize="large" />;
    case "email":
      return <EmailIcon fontSize="large" />;
    case "address":
      return <LocationOnIcon fontSize="large" />;
    default:
      return <></>;
  }
};

const ContactBox = (props: { name: string; text: string | JSX.Element }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card elevation={8} sx={{ width: "300px", height: "200px", p: "1.5rem" }}>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              width: 70,
              height: 70,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <ContactIcon icon={props.name} />
          </Avatar>
          <Typography variant="h5" align="center" sx={{ pt: "2rem" }}>
            {props.text}
          </Typography>
        </Container>
      </Card>
    </Box>
  );
};

export default ContactBox;
