import {
  Grid,
  Typography,
  useTheme,
  Container,
  Button,
  Box,
  Paper,
} from "@mui/material";

const Apply = () => {
  const theme = useTheme();
  return (
    <>
      <Paper elevation={8}>
        <Grid
          container
          sx={{
            mb: "2rem",
            mt: 0,
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }}
        >
          <Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ p: "4rem" }}>
                Work For Us
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ m: "2rem 0" }}>
        <Container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: "2rem" }}>
              August Groh & Sons, Inc. is an equal opportunity employer, we
              provide industry standard benefits such as one week paid vacations
              and six paid holidays per year. We offer flexible work schedules
              and many growth opportunities. We have an exceptionally low
              turnover rate and work mostly in team settings with some
              independent locations. Work schedules vary by client but include
              both day, evening, and weekend shifts. We are a 3rd generation
              family owned WBE (Women Business Enterprise) continuously
              operating for over ninety years! You're only a phone call away
              from speaking directly with the owners, Jo & Ric Groh. We are
              always interested in hearing from top quality workers. If you feel
              you have building services skills like area cleaning, hard & soft
              floor surface maintenance or commercial & industrial painting
              we're looking for, please send us your information!
            </Typography>
            <Typography variant="body1">
              After downloading the application below, you can either print the
              file out and bring it into the office or fax it to (513) 679-4062.
              Alternatively you can fill it out on your computer using Adobe
              Acrobat Reader and email the finished document to pobox@groh.com
            </Typography>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ m: "2rem 0" }}
              href="ag&s_application.pdf"
              download={true}
            >
              Download Application
            </Button>
          </Box>
        </Container>
      </Grid>
    </>
  );
};

export default Apply;
