import { Grid, Typography, useTheme, Paper, Container } from "@mui/material";
import BulletedListBox from "../components/BulletedListBox";
import ContactBanner from "../components/ContactBanner";

const Cleaning = () => {
  const theme = useTheme();
  return (
    <>
      <Paper elevation={8}>
        <Grid
          container
          sx={{
            mb: "2rem",
            mt: 0,
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }}
        >
          <Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ p: "4rem" }}>
                Cleaning Services
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ m: "2rem 0" }}>
        <Container>
          <Grid item xs={12}>
            <Typography variant="body1">
              We have been doing Cincinnati's dirty work for 96 years as a
              painting company. Then in the late 1970's we started in the
              janitorial business. Our largest client asked us to assume the
              operation of a cleaning company which doubled our business
              overnight. Jack's son, Ric, and his wife Jo, who had both been
              working in the Company's operations divisions, then carried forth
              this tradition in management while updating the business with
              modern technology and management principles for the new
              millennium.
            </Typography>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item md={4} xs={12} sx={{ p: "2rem", textAlign: "center" }}>
              <img
                src="hallway_cleaning.png"
                alt="office hallway with couches"
                width="300px"
                height="320px"
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ p: "2rem", textAlign: "center" }}>
              <img
                src="office_hallway.png"
                alt="office hallway with cubicles"
                width="300px"
                height="320px"
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ p: "2rem", textAlign: "center" }}>
              <img
                src="classroom_cleaning.jpeg"
                alt="classroom with round tables"
                width="300px"
                height="320px"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mb: "2rem", justifyContent: "center" }}>
            <Typography variant="body1" sx={{ mb: "2rem" }}>
              Our janitorial division can take care of all your cleaning needs
              from your daily cleaning, carpets, stripping and waxing, as well
              as project cleaning.
            </Typography>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-evenly"
              alignItems="center"
              sx={{ mb: "2rem" }}
            >
              <BulletedListBox size={4}>
                <Typography variant="body1" component={"div"} color="white">
                  <ul>
                    <li>Cleaning of offices, restrooms, and common areas</li>
                    <li>
                      Environmentally controlled, laboratory, and/or sensitive
                      room cleaning
                    </li>
                    <li>Floor stripping and waxing</li>
                    <li>Interior window washing</li>
                    <li>Food handling area cleaning</li>
                    <li>Recycling and trash removal</li>
                    <li>Task-specific labor</li>
                  </ul>
                </Typography>
              </BulletedListBox>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ textAlign: "center", mt: "2rem" }}
              >
                <img
                  src="floor_waxing.jpeg"
                  alt="man waxing floor with machine"
                  width="400px"
                  height="400px"
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{ textAlign: "center", mt: "2rem" }}
                order={{ md: 1, xs: 2 }}
              >
                <img
                  src="sweeping_example.jpeg"
                  alt="woman sweeping bathroom"
                  width="400px"
                  height="400px"
                />
              </Grid>
              <BulletedListBox size={4} order={{ md: 2, xs: 1 }}>
                <Typography variant="body1" component={"div"} color="white">
                  <ul>
                    <li>Purchasing consumables and stocking restrooms</li>
                    <li>Upholstery cleaning</li>
                    <li>Specialty project cleaning</li>
                    <li>Carpet cleaning</li>
                    <li>Pressure washing - cold water/high pressure</li>
                    <li>Drapery cleaning</li>
                    <li>Blinds cleaning</li>
                  </ul>
                </Typography>
              </BulletedListBox>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: "2rem" }}>
            <Grid item>
              <Typography>
                While we have some smaller clients, many of our buildings are
                100,000 SF or more. As the national average for employee
                turnover for janitorial companies is upwards of 200%, our
                employee turnover rate is currently at 31.6%, with our average
                contract lasting 7 years. Jo continues to improve customer
                satisfaction as well as employee retention, and is in the
                process of taking the company to a new level by going 'green'.
                As a SEMS certified business, we continually strive to improve
                our environmental footprint. Make August Groh & Sons a part of
                your facilities team.
              </Typography>
            </Grid>
          </Grid>
          <ContactBanner />
        </Container>
      </Grid>
    </>
  );
};

export default Cleaning;
