import { Grid, Typography, useTheme, Paper, Container } from "@mui/material";
import ContactBanner from "../components/ContactBanner";

const Painting = () => {
  const theme = useTheme();
  return (
    <>
      <Paper elevation={8}>
        <Grid
          container
          sx={{
            mb: "2rem",
            mt: 0,
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }}
        >
          <Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ p: "4rem" }}>
                Painting Services
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ m: "2rem 0" }}>
        <Container>
          <Grid container sx={{ mb: "2rem" }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                August Groh & Sons, Inc. has been serving the Greater Cincinnati
                business community since 1926, providing top quality commercial
                painting and refinishing services. Painting services include:
                Interior and exterior Painting. Metal doors, windows and
                furniture refinishing and sealing. Surface preparation:
                caulking, patching, hand sanding, power grinding, chemical peel,
                priming. SSPC surface preservation and sealing Surface and
                equipment restoration Pressure washing for hot water/low
                pressure and cold water/high pressure blasting. A wide range of
                application methods including airless sprayer, brush and roller
                painting, HVLP, and conventional air-assisted methods. A range
                of coatings from simple enamels and latex products to technical
                epoxies and catalyzed coatings; We also provide office furniture
                and small equipment/machinery refurbishing at our Reading, Ohio
                facility. This operation utilizes an enclosed and heated
                downdraft style spray booth, with the latest coatings and
                application methods. As a SEMS certified business, we
                continually strive to improve our environmental footprint.
              </Typography>
              <Grid container item alignItems="center">
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{ p: "1rem", textAlign: "center" }}
                >
                  <img src="painting_example_3.jpeg" alt="man painting door" />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{ p: "1rem", textAlign: "center" }}
                >
                  <img
                    src="file_cabinets.jpeg"
                    alt="before and after of cabinets"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{ p: "1rem", textAlign: "center" }}
                >
                  <img src="bench_after.jpeg" alt="painted bench" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ContactBanner />
        </Container>
      </Grid>
    </>
  );
};

export default Painting;
