import { Container, Card } from "@mui/material";

const ProfilePic = (props: { img: string; name: string }) => {
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Card elevation={8} sx={{ width: "300px", height: "350px" }}>
        <img src={props.img} alt={props.name} width="100%" height="100%" />
      </Card>
    </Container>
  );
};

export default ProfilePic;
