import { AppBar, Toolbar, Container } from "@mui/material";

import MobileMenuBar from "./MobileMenuBar";
import WebMenuBar from "./WebMenuBar";

export type MenuOptions = {
  title: string;
  route: string;
};

const pages: MenuOptions[] = [
  { title: "Contact", route: "contact" },
  { title: "Work For Us", route: "apply" },
];

const servicesItems: MenuOptions[] = [
  { title: "Cleaning", route: "cleaning" },
  { title: "Painting", route: "painting" },
];

const employeeLinks: MenuOptions[] = [
  { title: "Payroll", route: "https://www.adp.com/logins.aspx" },
  { title: "Safety Training", route: "https://www.safetysoft.com" },
];

const aboutUsItems: MenuOptions[] = [
  { title: "Who We Are", route: "about-us" },
  { title: "Our Clients", route: "clients" },
];

const MenuBar = () => {
  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MobileMenuBar
            lists={pages}
            servicesOptions={servicesItems}
            employeeLinks={employeeLinks}
            aboutUsItems={aboutUsItems}
          />
          <WebMenuBar
            lists={pages}
            servicesOptions={servicesItems}
            employeeLinks={employeeLinks}
            aboutUsItems={aboutUsItems}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MenuBar;
