import { Grid, Typography, useTheme, Paper, Link } from "@mui/material";
import ContactBox from "../components/ContactBox";

const contactInfo = [
  {
    key: "phone",
    value: "513-821-0090",
  },
  {
    key: "email",
    value: (
      <Link sx={{ color: "#000000" }} href="mailto:pobox@groh.com">
        pobox@groh.com
      </Link>
    ),
  },
  {
    key: "address",
    value: "8832 Reading Road Cincinnati, Ohio 45215",
  },
];

const Contact = () => {
  const theme = useTheme();
  return (
    <>
      <Paper elevation={8}>
        <Grid
          container
          sx={{
            mb: "2rem",
            mt: 0,
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }}
        >
          <Grid item container sx={{ mb: "2rem", pt: 0 }}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ p: "2rem" }}>
                Contact Us
              </Typography>
            </Grid>
            {contactInfo.map((info) => {
              return (
                <Grid item xs={12} md={4} sx={{ mb: "2rem" }}>
                  <ContactBox name={info.key} text={info.value} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
      <Grid container item sx={{ mb: "4rem", justifyContent: "center" }}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3090.940722552265!2d-84.44364268462867!3d39.221511979521864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88404d7cf0be0b0f%3A0x51044d2008b2c7d7!2sAugust%20Groh%20%26%20Sons%2C%20Inc.!5e0!3m2!1sen!2sus!4v1651588858216!5m2!1sen!2sus"
          style={{ border: 0 }}
          width="450px"
          height="350px"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Grid>
    </>
  );
};

export default Contact;
