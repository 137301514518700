import React from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuOptions } from "./MenuBar";
import MobileMenuDropdown from "./MobileMenuDropdown";

type MobileMenuBarProps = {
  lists: MenuOptions[];
  servicesOptions: MenuOptions[];
  employeeLinks: MenuOptions[];
  aboutUsItems: MenuOptions[];
};

const MobileMenuBar = (props: MobileMenuBarProps) => {
  const { lists, servicesOptions, employeeLinks, aboutUsItems } = props;
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { sm: "flex", md: "none" } }}>
        <IconButton size="large" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-mobile-app-bar"
          disableAutoFocusItem
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MobileMenuDropdown
            id="services"
            name="Services"
            setMenuAnchor={setMenuAnchor}
            menuOptions={servicesOptions}
          />
          <MobileMenuDropdown
            id="about-us"
            name="Who We Are"
            setMenuAnchor={setMenuAnchor}
            menuOptions={aboutUsItems}
          />
          {lists.map((list) => (
            <MenuItem key={list.title} onClick={handleMenuClose}>
              <Typography textAlign="center">
                <Link
                  noWrap
                  underline="none"
                  component={RouterLink}
                  to={`/${list.route}`}
                >
                  {list.title}
                </Link>
              </Typography>
            </MenuItem>
          ))}
          <MobileMenuDropdown
            id="employee-links"
            name="Employee Links"
            setMenuAnchor={setMenuAnchor}
            menuOptions={employeeLinks}
            external={true}
          />
        </Menu>
      </Box>
      <Link
        to="/"
        component={RouterLink}
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "none" },
          p: ".5rem 0",
        }}
      >
        <img
          src="groh_logo.png"
          alt="logo"
          height="67px"
          width="120px"
          style={{ cursor: "pointer" }}
        />
      </Link>
    </>
  );
};

export default MobileMenuBar;
