import {
  Container,
  Grid,
  Typography,
  Link,
  useTheme,
  Button,
  alpha,
  useMediaQuery,
  Paper,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import BulletedListBox from "../components/BulletedListBox";
import ClientTestimony from "../components/ClientTestimony";
import ContactBanner from "../components/ContactBanner";

const testimonies = [
  {
    name: "- James M. May, Project Executive",
    text: "August Groh & Sons has performed numerous projects in both scopes for us over the last 25 years. We find their pricing to be competitive, their work performance is done with quality, care, and professionalism, and their service and responsiveness are excellent. Jo and Ric provide good management with communication, processes, and customer satisfaction. Their key personnel are dedicated and get the job done properly.",
  },
  {
    name: "- CPD Eric",
    text: "August Groh & Sons has provided services for the Narcotic and Vice units for approximately the past three years. The service they provide is beyond satisfactory. They are thorough, dependable, and professional. They provide the best service I have seen in 24 years with the city. Additionally, our units have sensitive material and information in our offices that I feel is not at risk.",
  },
  {
    name: "- Jeff Langdon, Chief Operating Officer",
    text: "August Groh & Sons has provided custodial services for Three Rivers Local School District for nearly 15 years. During that fifteen-year timeframe our buildings were always clean and a showcase for our community. They were always dependable and provided quality work for our school district. They were always flexible as we facilitate many evening and weekend activities within our schools.",
  },
];

const Home = () => {
  const theme = useTheme();
  return (
    <Grid container sx={{ mb: "2rem" }}>
      <Grid xs={12}>
        <div
          style={{
            position: "relative",
            height: 0,
            paddingTop: "calc(1694 / 3251 * 100%)",
          }}
        >
          <img
            src="building.jpg"
            alt="building"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <Grid
            item
            md={5}
            lg={5}
            sx={{
              position: "absolute",
              top: "8rem",
              right: "8rem",
            }}
          >
            <Paper
              elevation={16}
              sx={{
                backgroundColor: alpha(theme.palette.primary.main, 0.85),
                color: "white",
                p: "2rem",
                display: { md: "flex", xs: "none" },
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant={useMediaQuery("(min-width:1200px)") ? "h4" : "h6"}
              >
                August Groh & Sons has been in business since 1926, providing
                the greater Cincinnati area with quality commercial janitorial
                and painting. Contact us today and&nbsp;
                <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  "Let us do your dirty work!"
                </span>
              </Typography>
              <Link
                to={`/contact`}
                component={RouterLink}
                noWrap
                underline="none"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ mt: "1rem" }}
                >
                  Contact Us
                </Button>
              </Link>
            </Paper>
          </Grid>
        </div>
      </Grid>
      <Container>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ m: "2rem 0" }}>
            August Groh & Sons, Inc. has been serving the Greater Cincinnati
            Business Community since 1926; providing quality commercial
            painting, specialty finishes, and commercial janitorial services. We
            seek out up & coming educational, technological, and environmental
            advances to maintain or expand our expertise in the commercial
            painting and janitorial industries, while staying mindful of the
            environment. As a third generation family owned business, safety is
            a top priority, providing employees a safe jobsite to come to
            everyday, but everyone leaves to go home at the end of the day. This
            is implemented daily by abiding by OSHA standards safety training
            program, extensive auditing and inspection procedures of all
            employees and equipment, Safety Data Sheet (SDS) Binders in every
            company vehicle and at every jobsite, an employee-supportive human
            resources department and management staff, pre-employment background
            checks, as well as utilizing DFWP (Drug-Free Workplace) through the
            BWC (Bureau of Worker's Compensation) to provide 11-panel drug
            testing, reasonable suspicion, and random testing throughout
            employment. These support systems, as well as many others proudly
            continue the company's long reputation as a great company to work
            with.
          </Typography>
          <Typography variant="body1" align="center" sx={{ m: "4rem 0" }}>
            We believe our mission statement says it all... "We strive to be an
            expanding building maintenance company, offering our clients high
            quality and dependability in all of the services we provide. Every
            employee is a key to our success and participates in improving work
            and life as the company's growth continues."
          </Typography>
        </Grid>
        <Paper elevation={16}>
          <Grid
            container
            item
            sx={{
              mb: "4rem",
              alignItems: "center",
              backgroundColor: theme.palette.primary.main,
              p: "1rem",
            }}
          >
            <Grid item xs={12} md={7}>
              <Container>
                <Typography variant="h6" sx={{ m: "1rem 0", color: "white" }}>
                  <Link
                    to={`/cleaning`}
                    component={RouterLink}
                    color="white"
                    noWrap
                    underline="none"
                  >
                    Janitorial Services
                  </Link>
                </Typography>
                <Typography variant="body1" color="white">
                  Our janitorial division provides on-site cleaning of offices,
                  GMP laboratories, sensitive and/or confidential areas,
                  restrooms, central areas, and special project cleaning. With
                  recycling and trash removal, 'class 100-10,000 clean room'
                  cleaning, construction clean up and task-specific labor
                  customized to fit your needs. We also offer upholstery and
                  carpet cleaning, floor stripping and waxing by hand or with a
                  machine, as well as interior window washing.
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Link
                    to={`/cleaning`}
                    component={RouterLink}
                    noWrap
                    underline="none"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      sx={{ mt: "2rem" }}
                    >
                      Learn More
                    </Button>
                  </Link>
                </Box>
              </Container>
            </Grid>
            <Grid item xs={12} md={5}>
              <Container
                sx={{ display: "flex", justifyContent: "center", p: "1rem" }}
              >
                <img
                  src="cleaning_example.jpg"
                  alt="cleaning example"
                  width="350px"
                  height="400px"
                />
              </Container>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={16}>
          <Grid
            container
            item
            sx={{
              mb: "4rem",
              alignItems: "center",
              backgroundColor: theme.palette.primary.main,
              p: "1rem",
            }}
          >
            <Grid item xs={12} md={7}>
              <Container>
                <Typography variant="h6" sx={{ m: "1rem 0", color: "white" }}>
                  <Link
                    to={`/painting`}
                    component={RouterLink}
                    color="white"
                    noWrap
                    underline="none"
                  >
                    Painting Services
                  </Link>
                </Typography>
                <Typography variant="body1" color="white">
                  Our painting division works with all kinds of application
                  methods for a wide variety of coatings, from simple enamels
                  and latex products using traditional brush and roller
                  applications to highly technical epoxies and catalyzed
                  coatings applied by airless, HVLP, and conventional
                  air-assisted methods. We also provide office furniture and
                  small equipment/machinery refurbishing at our Reading
                  facility. This operation utilizes an enclosed and heated
                  downdraft style spray booth, with the latest coatings and
                  application methods.
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Link
                    to={`/painting`}
                    component={RouterLink}
                    noWrap
                    underline="none"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      sx={{ mt: "2rem" }}
                    >
                      Learn More
                    </Button>
                  </Link>
                </Box>
              </Container>
            </Grid>
            <Grid item xs={12} md={5}>
              <Container
                sx={{ display: "flex", justifyContent: "center", p: "1rem" }}
              >
                <img
                  src="painting_example.jpeg"
                  alt="painting example"
                  width="350px"
                  height="400px"
                />
              </Container>
            </Grid>
          </Grid>
        </Paper>
        <Grid container item sx={{ mb: "2rem" }}>
          <Container>
            <Typography variant="h6">
              Company Awards & Certifications
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" component={"div"}>
                  <ul>
                    <li>Certified Woman-Owned Business Enterprise (WBENC)</li>
                    <li>MSDGC - Certified Small Business Enterprise (SBE)</li>
                    <li>
                      Jeffrey Butland Award 2011 'Family-Owned Business of the
                      Year
                    </li>
                    <li>
                      Building Service Contractors Association International
                      (BSCAI)
                    </li>
                    <li>
                      Painting Contractors of America Business Member (PCA)
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" component={"div"}>
                  <ul>
                    <li>
                      GREEN Cincinnati - Certified Small Business Enterprise
                      (SBE)
                    </li>
                    <li>SEMS Third-Party Business Certification</li>
                    <li>Cincinnati Business Regional Chamber Member</li>
                    <li>
                      National Association of Women in Construction (NAWIC)
                    </li>
                    <li>
                      Steel Structures Painting Council Business Member (SSPC)
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <BulletedListBox size={4} mb={"2rem"}>
            <Typography variant="h6" sx={{ m: "1rem 0", color: "white" }}>
              Quality Control
            </Typography>
            <Typography variant="body1" color="white">
              At August Groh & Sons, we work hard to provide superior quality
              service to our clients. To this end, we have developed and
              consistently implement a five-part Quality Assessment Program
              which includes:
            </Typography>
            <Typography variant="body1" component={"div"} color="white">
              <ul>
                <li>Daily Random Supervisory Inspections</li>
                <li>Weekly Customer Interface</li>
                <li>Monthly Management Audits</li>
                <li>Quarterly Client Inspections</li>
                <li>Web Accessible Service Survey</li>
              </ul>
            </Typography>
          </BulletedListBox>
          <BulletedListBox size={4} mb={"2rem"}>
            <Typography variant="h6" sx={{ m: "1rem 0", color: "white" }}>
              Employee Safety
            </Typography>
            <Typography variant="body1" color="white">
              We understand the importance of keeping our employees up to date
              on key safety requirements. All of our employees are required to
              participate in a variety of safety and skill training programs:
            </Typography>
            <Typography variant="body1" component={"div"} color="white">
              <ul>
                <li>New Induction HAZMAT and Blood Borne Pathogen Awareness</li>
                <li>General Job Safety and Skills Assessment Analysis</li>
                <li>Monthly Safety Meetings</li>
                <li>Semi-Annual Skills Training Programs</li>
                <li>Janitorial Training Center</li>
              </ul>
            </Typography>
          </BulletedListBox>
        </Grid>
        <Grid item container sx={{ mb: "2rem" }}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" sx={{ mb: "2rem" }}>
              Client Testimonies
            </Typography>
          </Grid>
          {testimonies.map((testimony) => {
            return (
              <Grid item xs={12} md={4} sx={{ mb: "2rem" }}>
                <ClientTestimony name={testimony.name} text={testimony.text} />
              </Grid>
            );
          })}
        </Grid>
        <ContactBanner />
      </Container>
    </Grid>
  );
};

export default Home;
