import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export type FooterLink = {
  external?: boolean;
  linkUrl: string;
  linkTitle: string;
};

const FooterItem = (props: { link: FooterLink }) => {
  const { link } = props;
  return (
    <>
      {link.external ? (
        <Link
          href={link.linkUrl}
          color="white"
          noWrap
          underline="none"
          target="_blank"
          rel="noopener"
        >
          {link.linkTitle}
        </Link>
      ) : (
        <Link
          to={link.linkUrl}
          component={RouterLink}
          color="white"
          noWrap
          underline="none"
        >
          {link.linkTitle}
        </Link>
      )}
    </>
  );
};

export default FooterItem;
