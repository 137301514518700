import { Grid, Typography, useTheme, Paper, Container } from "@mui/material";
import ContactBanner from "../components/ContactBanner";

const Clients = () => {
  const theme = useTheme();
  return (
    <>
      <Paper elevation={8}>
        <Grid
          container
          sx={{
            mb: "2rem",
            mt: 0,
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }}
        >
          <Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ p: "4rem" }}>
                Our Clients
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ m: "1rem 0" }}>
        <Container>
          <Grid item xs={12}>
            <Typography variant="body1">
              We have been serving the Greater Cincinnati and Northern Kentucky
              area for 96 years. Therefore, we have had many clients over the
              years. Our largest and longest client has been The Proctor &
              Gamble Company, starting with P&G back in the early 1930's as a
              painting company and adding janitorial services in the 1970's as
              well as a whole list of other services which continue to this day.
              We've performed work for Governments, Schools, Health Care
              Facilities, Fortune 500 Companies, Manufacturing Facilities, Dairy
              Companies, Chemical Companies, Property Management Companies,
              Clean Room Cleaning for Silicone Wafer Manufacturers, Paper
              Machine Painting, and Tank Farm Painting.
            </Typography>
            <Typography variant="body1" sx={{ m: "2rem 0" }}>
              We have many years of experience in various industries. To name
              just a few, currently some of our clients include the City of
              Cincinnati/Hamilton County (MSD, GCWW, CPD), Flagel, Huber,
              Flagel, and PSA Airlines. We started daily cleaning for the City
              of Cincinnati in 2011, performed some painting and electrostatic
              spraying for Covid-19, and have added multiple sites since then.
              We started at the Metropolitan Sewer District in 2012 stripping
              and waxing floors and eventually performing daily cleaning at
              various locations, as well as electrostatic spraying for Covid-19.
              We have been cleaning daily at Flagel, Huber, Flagel since 2003
              and over the years have added painting projects as well as carpet
              cleaning at their Cincinnati and Dayton locations. We have been
              providing daily cleaning for PSA Airlines since 2019, and added
              Envoy Airline maintenance offices shortly after.
            </Typography>
            <Typography variant="body1" sx={{ m: "2rem 0" }}>
              Although we have had many and various clients over the years, such
              as SUMCO, CPD, Duke Realty, Duke Convention Center, etc, we always
              became part of their team. Aspired to make their job easier,
              whether that was helping to solve problems or just being low
              maintenance so they could focus on more important things knowing
              that we did what we said we would do, and if there were issues, we
              responded promptly and corrected the issue in a timely manner.
            </Typography>
          </Grid>
          <ContactBanner />
        </Container>
      </Grid>
    </>
  );
};

export default Clients;
