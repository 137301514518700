import { Paper, Grid, Typography, Button, Link, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ContactBanner = () => {
  const theme = useTheme();
  return (
    <Paper elevation={16}>
      <Grid
        container
        sx={{
          mb: "2rem",
          backgroundColor: theme.palette.primary.main,
          color: "white",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography variant="h4" sx={{ p: "2rem 0 0 0" }}>
          Get In Touch
        </Typography>
        <Typography variant="h5" sx={{ p: "2rem 0 0 0", fontStyle: "italic" }}>
          Let us do your dirty work!
        </Typography>
        <Link to={`/contact`} component={RouterLink} noWrap underline="none">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ m: "2rem 0" }}
          >
            Contact Us
          </Button>
        </Link>
      </Grid>
    </Paper>
  );
};

export default ContactBanner;
