import { Container, Grid, Typography, useTheme, Paper } from "@mui/material";
import ProfileDescription from "../components/Profile/ProfileDescription";
import ProfilePic from "../components/Profile/ProfilePic";

const AboutUs = () => {
  const theme = useTheme();
  return (
    <>
      <Paper elevation={8}>
        <Grid
          container
          sx={{
            mb: "2rem",
            mt: 0,
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          }}
        >
          <Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" sx={{ p: "4rem" }}>
                Who We Are
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ m: "1rem 0" }}>
        <Container>
          <Grid item xs={12}>
            <Typography variant="body1">
              The August Groh & Sons Company, started in 1926, is now in its
              third generation of management with over eighty years of
              experience. With two divisions: Painting and Environmental
              Services - August Groh & Sons, Inc. provides a comprehensive array
              of office maintenance services, including programmed commercial
              building cleaning, commercial cyclical painting contracts,
              facility maintenance painting programs, and office furniture and
              equipment refurbishing. Since the early years when business was
              primarily directed at the Procter & Gamble Company, building
              maintenance and painting services has become the core of our
              business. Our list of clients has grown to include real estate
              development companies, commercial building developers, and other
              recognized industries in the Greater Cincinnati market. The two
              divisions now account for approximately one million dollars in
              sales per year. The corporate offices, shop, warehouse, training
              facilities, and climate controlled spray booth are located in
              Reading, Ohio.
            </Typography>
            <Typography variant="body1" sx={{ m: "2rem 0" }}>
              From the beginning, August “Gus” Groh and his two sons, Jack and
              Ron, nurtured the idea of combining hard work and attention to
              customer needs to create a formula for success. The business was
              formed as a painting contractor for commercial and residential
              work, but grew steadily by offering services to an expanding
              client base in and around Cincinnati. In the late 1970's assuming
              operation of a cleaning company for a client doubled the business
              overnight.
            </Typography>
            <Paper elevation={16}>
              <Grid
                container
                item
                alignItems="center"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  mb: "4rem",
                  color: "white",
                }}
              >
                <Grid item md={4} xs={12} sx={{ p: "2rem 0" }}>
                  <ProfilePic img="august.jpeg" name="august" />
                  <Container sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="subtitle2">August Groh</Typography>
                  </Container>
                </Grid>
                <Grid item md={4} xs={12} sx={{ p: "2rem 0" }}>
                  <ProfilePic img="jack.jpeg" name="jack" />
                  <Container sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Jack Groh</Typography>
                  </Container>
                </Grid>
                <Grid item md={4} xs={12} sx={{ p: "2rem 0" }}>
                  <ProfilePic img="ron.jpeg" name="ron" />
                  <Container sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Ron Groh</Typography>
                  </Container>
                </Grid>
              </Grid>
            </Paper>
            <Typography variant="body1" sx={{ m: "2rem 0" }}>
              Jack's son, Ric and his wife Jo who had both been working in the
              Company's operations divisions then carried forth this tradition
              in management while updating the business with modern technology
              and management principles for the new millennium. As the remaining
              partners retired, and Ric's growing real estate opportunities and
              other investments took more of his time, Jo assumed the daily
              operations of the company. Jo continues to improve customer
              satisfaction as well as employee retention, and is in the process
              of taking the company to a new level by going 'green'.
            </Typography>
            <Typography variant="body1" sx={{ m: "2rem 0" }}>
              Over the years, the painting and janitorial departments have
              expanded our client base to more surrounding areas near Cincinnati
              and Kentucky. With hope for the future, we cast our eyes forward
              striving to reduce our carbon footprint, while providing the same
              high level service our clients have come to expect.
            </Typography>
          </Grid>
          <Paper elevation={16}>
            <Grid
              container
              item
              alignItems="center"
              sx={{
                backgroundColor: theme.palette.primary.main,
                mb: "4rem",
                color: "white",
              }}
            >
              <Grid item md={6} xs={12} sx={{ pt: "2rem" }}>
                <ProfilePic img="jo.jpg" name="jo" />
                <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="subtitle2">
                    Jo Groh, President
                  </Typography>
                </Container>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: "1rem" }}>
                <ProfileDescription
                  description="Jo started working for August Groh & Sons, Inc. in 1981 in the Environmental
Services Division while attending Southern Ohio College. While raising her children,
she continued to contribute in various roles, including human resources and office
management. In 2007 she became President and a third generation owner, and the
business was certified as a WBE by the Ohio River Valley Women's Business Council.
The company is also a certified WBE by the City of Cincinnati.
Jo is a LEED Green Associate, a graduate of the Ohio River Valley Business
Development Program. A certified Construction Industry Technician and graduate of the
Cincinnati Construction Technical Support Center Education and Training Program. Jo
has an Associate's Degree from Southern Ohio College in Cincinnati, Ohio. She serves
on several committees for the Women Business Enterprise National Council (WBENC)
as well as having served on the Board of Directors of the National Association of
Women in Construction (NAWIC) for two terms and was chair of the NAWIC CAD
Program and volunteered her time to many NAWIC events to promote women and girls
in construction careers. She has been a longtime volunteer for Girl Scouting, including
being a troop leader for 14 years.
Jo holds membership in the following associations:
Building Service Contractors Association International (BSCAI) and the
Cincinnati Chamber of Commerce"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={16}>
            <Grid
              container
              item
              alignItems="center"
              sx={{
                backgroundColor: theme.palette.primary.main,
                mb: "4rem",
                color: "white",
              }}
            >
              <Grid
                item
                md={6}
                xs={12}
                order={{ md: 1, xs: 2 }}
                sx={{ p: "1rem" }}
              >
                <ProfileDescription
                  description="Ric started working for August Groh & Sons, Inc. in 1976. While working his way
up through the company he continued to contribute in various roles, including Site
Maintenance Painter for their largest client. Spent time in the janitorial division in
various capacities. Eventually made Paint Operations Manager and then Vice
President of Operations.
Ric is a graduate of the University of Cincinnati's Executive Program (UCEP). As
well as being a member of the Goering Center for Family and Private Business.
Ric served as President of the Ohio Counsel and local chapter of the Painting and
Decorating Contractors of America (PDCA) for many years as well as serving as president of the Community Association Institute (CAI). He also volunteered as
an Elementary School Parent Aide and a Parents Aiding Learning Tutor for several
years.
Ric has held membership in the following associations:
Building Service Contractors Association International (BSCAI),
Cincinnati Chamber of Commerce,
Community Associations Institute, Ohio Valley Chapter (CAI), and
Painting and Decorating Contractors of America (PDCA)"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                order={{ md: 2, xs: 1 }}
                sx={{ pt: "2rem" }}
              >
                <ProfilePic img="ric.jpg" name="ric" />
                <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="subtitle2">
                    Ric Groh, Vice President
                  </Typography>
                </Container>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Grid>
    </>
  );
};

export default AboutUs;
