import { Grid, Typography, Link, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const NotFound = () => {
  return (
    <Grid container xs={12} sx={{ justifyContent: "center", m: "12rem 0" }}>
      <Grid container item xs={12} sx={{ justifyContent: "center" }}>
        <Typography variant="h3">There's nothing here!</Typography>
      </Grid>
      <Link to={`/`} component={RouterLink} noWrap underline="none">
        <Button variant="contained" size="large" sx={{ mt: "2rem" }}>
          Go back home
        </Button>
      </Link>
    </Grid>
  );
};

export default NotFound;
