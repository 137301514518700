import React from "react";
import { MenuItem, Typography, Link, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuOptions } from "./MenuBar";

const MobileMenuDropdown = (props: {
  id: string;
  name: string;
  setMenuAnchor: any;
  menuOptions: MenuOptions[];
  external?: boolean;
}) => {
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleMenuClose = () => {
    setChecked(false);
    props.setMenuAnchor(null);
  };

  return (
    <>
      <MenuItem
        key={props.id}
        onClick={handleChange}
        sx={{ color: theme.palette.primary.main }}
      >
        <Typography textAlign="center" sx={{ mr: "0.5rem" }}>
          {props.name}
        </Typography>
        {checked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </MenuItem>
      <Collapse in={checked}>
        {props.menuOptions.map((option) => (
          <MenuItem key={option.title} onClick={handleMenuClose}>
            <Typography textAlign="center" sx={{ ml: "1rem" }}>
              {props.external ? (
                <Link
                  noWrap
                  underline="none"
                  href={option.route}
                  target="_blank"
                  rel="noopener"
                >
                  {option.title}
                </Link>
              ) : (
                <Link
                  noWrap
                  underline="none"
                  component={RouterLink}
                  to={`/${option.route}`}
                >
                  {option.title}
                </Link>
              )}
            </Typography>
          </MenuItem>
        ))}
      </Collapse>
    </>
  );
};

export default MobileMenuDropdown;
