import { Container, Typography } from "@mui/material";

const ProfileDescription = (props: { description: string }) => {
  return (
    <Container sx={{ margin: "4rem 0" }}>
      <Typography variant="body1">{props.description}</Typography>
    </Container>
  );
};

export default ProfileDescription;
