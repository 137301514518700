import { Grid, Paper, Container } from "@mui/material";
import theme from "../theme";

const BulletedListBox = (props: {
  children: any;
  size: number;
  mb?: string;
  order?: any;
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      md={props.size}
      order={props.order}
      sx={{ justifyContent: "center" }}
    >
      <Paper
        elevation={16}
        sx={{
          alignItems: "center",
          backgroundColor: theme.palette.primary.main,
          p: "1rem",
          mb: props.mb ?? 0,
        }}
      >
        <Container>{props.children}</Container>
      </Paper>
    </Grid>
  );
};

export default BulletedListBox;
