import { Button, Link, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { MenuOptions } from "./MenuBar";
import WebMenuDropdown from "./WebMenuDropdown";

type WebMenuBarProps = {
  lists: MenuOptions[];
  servicesOptions: MenuOptions[];
  employeeLinks: MenuOptions[];
  aboutUsItems: MenuOptions[];
};

const WebMenuBar = (props: WebMenuBarProps) => {
  const { lists, servicesOptions, employeeLinks, aboutUsItems } = props;

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="row"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item sm={2} sx={{ p: ".5rem 0" }}>
          <Link to="/" component={RouterLink}>
            <img
              src="groh_logo.png"
              alt="logo"
              height="67px"
              width="120px"
              style={{ cursor: "pointer" }}
            />
          </Link>
        </Grid>
        <Grid container item sm={10} justifyContent="flex-end">
          <WebMenuDropdown
            id="services"
            name="Services"
            menuOptions={servicesOptions}
          />
          <WebMenuDropdown
            id="about-us"
            name="About Us"
            menuOptions={aboutUsItems}
          />
          {lists.map((list) => (
            <Button
              key={list.title}
              sx={{ my: 2 }}
              component={RouterLink}
              to={`/${list.route}`}
            >
              {list.title}
            </Button>
          ))}
          <WebMenuDropdown
            id="employee-links"
            name="Employee Links"
            menuOptions={employeeLinks}
            external={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WebMenuBar;
