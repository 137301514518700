import { Stack, Typography } from "@mui/material";
import FooterItem, { FooterLink } from "./FooterItem";

type FooterStackProps = {
  title: string;
  links: FooterLink[];
};

const FooterStack = (props: FooterStackProps) => {
  return (
    <Stack direction="column">
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {props.title}
      </Typography>
      {props.links.map((link) => {
        return (
          <Typography variant="body2">
            <FooterItem link={link} />
          </Typography>
        );
      })}
    </Stack>
  );
};

export default FooterStack;
