import { ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import MenuBar from "./components/MenuBar/MenuBar";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MenuBar />
      <Outlet />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
