import React from "react";
import { Button, Menu, MenuItem, Typography, Link } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuOptions } from "./MenuBar";
import { Link as RouterLink } from "react-router-dom";

const WebMenuDropdown = (props: {
  id: string;
  name: string;
  menuOptions: MenuOptions[];
  external?: boolean;
}) => {
  const [webMenuAnchor, setWebMenuAnchor] = React.useState<null | HTMLElement>(
    null
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setWebMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setWebMenuAnchor(null);
  };
  return (
    <div onMouseLeave={handleMenuClose}>
      <Button
        onMouseEnter={handleMenuOpen}
        sx={{ my: 2 }}
        endIcon={<ArrowDropDownIcon />}
        disableRipple
      >
        {props.name}
      </Button>
      <Menu
        id={props.id}
        anchorEl={webMenuAnchor}
        disableAutoFocusItem
        disablePortal
        sx={{ pointerEvents: "none" }}
        PaperProps={{
          style: { pointerEvents: "auto" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(webMenuAnchor)}
        onClose={handleMenuClose}
      >
        {props.menuOptions.map((option) => (
          <MenuItem key={option.title} onClick={handleMenuClose}>
            <Typography textAlign="center">
              {props.external ? (
                <Link
                  noWrap
                  underline="none"
                  href={option.route}
                  target="_blank"
                  rel="noopener"
                >
                  {option.title}
                </Link>
              ) : (
                <Link
                  noWrap
                  underline="none"
                  component={RouterLink}
                  to={`/${option.route}`}
                >
                  {option.title}
                </Link>
              )}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default WebMenuDropdown;
