import { Container, Card, Typography } from "@mui/material";

const ClientTestimony = (props: { name: string; text: string }) => {
  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", height: "100%" }}
    >
      <Card elevation={8} sx={{ p: "1.5rem" }}>
        <Typography variant="body1" sx={{ mb: "1rem" }}>
          {props.text}
        </Typography>
        <Typography variant="subtitle2">{props.name}</Typography>
      </Card>
    </Container>
  );
};

export default ClientTestimony;
